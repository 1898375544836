import { CustomCellRendererProps } from "@ag-grid-community/react";
import { HexType } from "@hex/common";
import React from "react";
import styled from "styled-components";

import { getOwnerName } from "../../../hex-list-2/hex-row/utils.js";
import { ProjectsTableSafeOrUnknownHexFragment } from "../../ProjectsTable.generated.js";
import { RowHeaderTableCellText, TableRowProjectTypeIcon } from "../styled.js";

export const ReadonlyNameCellRenderer = React.memo<
  CustomCellRendererProps<
    Pick<ProjectsTableSafeOrUnknownHexFragment, "hexType" | "creator" | "owner">
  >
>(function ReadonlyNameCellRenderer({ data }) {
  if (data == null) {
    return null;
  }
  const ownerName = getOwnerName(data);
  const text = `${data.hexType === HexType.COMPONENT ? "Component" : "Project"} ${`owned by ${ownerName}`}`;
  return (
    <ReadonlyNameContainer>
      <RowHeaderTableCellText $isUnknownHex={true}>
        {text}
      </RowHeaderTableCellText>
    </ReadonlyNameContainer>
  );
});

const ReadonlyNameContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${TableRowProjectTypeIcon} {
    padding-right: 8px;
  }
`;
